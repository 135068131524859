<template>
  <div>
    <div> <p  class="category">产品管理</p></div>
    <div>
      <el-form :inline="true" :model="form" class="demo-form-inline">
        <el-form-item label="标题">
          <el-input v-model="form.keyword"></el-input>
        </el-form-item>
        <el-form-item label="所属栏目">
          <el-select v-model="form.cateid" placeholder="请选择">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间">
          <el-date-picker
              v-model="form.datetime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>   <el-button align="right" @click="goTo(0)" class="category add" style="margin: 20px auto">新增</el-button>
      </el-form>

    </div>
    <el-table
        :data="tableData"
        style="width: 100%">
      <el-table-column
          label="ID"
          width="60">
        <template slot-scope="scope">
          <span style="margin-left: 10px" >{{ scope.row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="所属栏目"
          width="200"
      >
        <template slot-scope="scope">
          <span style="" v-html="scope.row.cate"></span>
        </template>
      </el-table-column>
      <el-table-column
          label="产品标题"
      >
        <template slot-scope="scope">
          <span style="margin-left: 10px" v-html="scope.row.title"></span>
        </template>
      </el-table-column>
      <el-table-column
          label="缩略图"
          width="180">
        <template slot-scope="scope">
          <span style=""><img :src="scope.row.img" style="max-width: 150px;max-height: 50px;"/></span>
        </template>
      </el-table-column>
      <el-table-column
          label="时间"
          width="180">
        <template slot-scope="scope">
          <span style="">{{ scope.row.time}}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="状态"
          width="180">
        <template slot-scope="scope">
          <span style="">{{ scope.row.status}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button size="mini" @click="goTo(scope.row.id)">编辑</el-button>
          <el-button
              size="mini"
              type="danger"
              @click="deleteContent(scope.$index,scope.row)">删除</el-button>
        </template>
      </el-table-column>

    </el-table>
    <el-pagination
        layout="prev, pager, next"
        :page-size=10
        @current-change="getProduct"
        :page-count="total"
    >
    </el-pagination>

  </div>
</template>
<script>
import axios from "axios";
// import Dialog from "@/views/pages/getContentView.vue"


export default {
  name: "ProductView",
  data() {
    return {
      form:{
        datetime:'',
        keyword:'',
        cateid:''
      },
      tableData: [],
      options:[],
      id: '',
      total: 100,
    }
  },
  components:{
  },
  created() {
    this.getCate();
    this.getProduct();
  },
  methods: {
    goTo(id){
      window.open('/getProduct?id='+id,'_blank');
      // this.$router.push('/getProduct?id='+id)
    },
    getCate(){
      axios.get('/api/Category/getCates.html').then((res)=>{
        if(res.data.code == 200){
          this.options = res.data.data;
        }
      });
    },
    getProduct(page = 1){
      var str = '';
      var keyword = this.form.keyword;
      const time = this.form.datetime;
      const cateid = this.form.cateid;
      if(keyword) str = str + '&keyword='+keyword
      if(time)  str = str+'&startime='+time[0]+'&endtime='+time[1];
      if(cateid)  str = str+'&cateid='+cateid;
      axios.get('/api/Product/index.html?page='+page+str).then((res)=>{
        if(res.data.code == 200){
          this.tableData = res.data.data;
          this.total = res.data.total
        }
      });
    },
    onSubmit() {
      this.getProduct()
    },
    deleteContent(index,row){
      let tdata = this.tableData;
      this.$confirm("是否确认删除","提示",{
        confirmButtonText:'确定',
        cancelButtonText:'取消',
      }).then(function (){
        axios.delete('/api/Product/deleteProduct.html',{data:{id:row.id}}).then((res)=>{
          tdata.splice(index, 1);
          alert(res.data.msg)
        });
      }).catch(function (){

      })

    }
  }
};
</script>
<style scoped lang=''>
</style>
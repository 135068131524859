<template>
  <div>
    <div> <p  class="category">banner管理</p></div>
    <div>
      <el-form :inline="true" :model="form" class="demo-form-inline">
        <el-form-item label="所属分类">
          <el-select v-model="form.cateid" placeholder="请选择">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>  <el-button  @click="goTo(0)" class="category add" style="margin: 20px auto">新增</el-button>
      </el-form>

    </div>
    <el-table
        :data="tableData"
        style="width: 100%">
      <el-table-column
          label="ID"
          width="180">
        <template slot-scope="scope">
          <span style="" >{{ scope.row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="banner标题"
          width="180">
        <template slot-scope="scope">
          <span style="" v-html="scope.row.b_name"></span>
        </template>
      </el-table-column>
      <el-table-column
          label="banner图"
          width="180">
        <template slot-scope="scope">
          <span style=""><img :src="scope.row.imgsrc" style="max-width: 150px;max-height: 50px;"/></span>
        </template>
      </el-table-column>
      <el-table-column
          label="所属栏目"
      >
        <template slot-scope="scope">
          <span style="">{{ scope.row.catename }}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="状态"
          width="180">
        <template slot-scope="scope">
          <span style="">{{ scope.row.status}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作"  width="180">
        <template slot-scope="scope">
          <el-button size="mini" @click="goTo(scope.row.id)">编辑</el-button>
          <el-button
              size="mini"
              type="danger"
              @click="deleteBanner(scope.$index,scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import axios from "axios";
// import Dialog from "@/views/pages/getContentView.vue"


export default {
  name: "BannerView",
  data() {
    return {
      form:{
        cateid:''
      },
      tableData: [],
      options: [],
      id: '',
      total: 100,
    }
  },
  components:{
  },
  created() {
    this.getCate();
    this.getCates();
  },
  methods: {
    goTo(id){
      this.$router.push('/getBanner?id='+id)
    },
    getCate(){
      var cateid = this.form.cateid
      var str = '';
      if(cateid) str = "?cateid="+cateid
      axios.get('/api/Banner/index.html'+str).then((res)=>{
        if(res.data.code == 200){
          this.tableData = res.data.data;
        }
      });
    },
    getCates(){
      axios.get('/api/Cate/getCates.html?type=banner').then((res)=>{
        if(res.data.code == 200){
          this.options = res.data.data;
        }
      });
    },
    onSubmit() {
      this.getCate()
    },
    deleteBanner(index,row){
      let tdata = this.tableData;
      this.$confirm("是否确认删除","提示",{
        confirmButtonText:'确定',
        cancelButtonText:'取消',
      }).then(function (){
        axios.delete('/api/Banner/deleteBanner.html',{data:{id:row.id}}).then((res)=>{
          tdata.splice(index, 1);
          alert(res.data.msg)
        });
      }).catch(function (){

      })

    }
  }
};
</script>
<style scoped lang=''>
</style>